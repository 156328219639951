import settings from './Settings';
import store from './store/index';
import Color from 'color';

function getUserTheme(container){
    return new Promise((resolve) => {
        window.ridestyler.ajax.send({action:'client/gettheme'}).then((thing) => {
            if(thing) store.state.theme = thing.Theme;

            if (thing.Theme.PrimaryColor) {
                const primaryColor = new Color(thing.Theme.PrimaryColor);
        
                container.style.setProperty('--primary-color', primaryColor);
                container.style.setProperty('--primary-color-active', primaryColor.darken(0.4).string());
            }
            resolve();
        });
    })
}

export default {
    initialize: function(){
        /** @type {HTMLElement} */
        const container = this.$el;
        const options = settings.options;

        let {clientHeight: containerHeight, clientWidth: containerWidth} = container;

        if (options.disableAutoContainerSizing !== true) {
            if (containerWidth <= 0) container.style.width = '100%';
            if (containerHeight <= 20) {
                if(window.innerWidth > window.innerHeight) container.style.height = (containerHeight = Math.floor(containerWidth / 2.2)) + 'px';
                else {
                    container.style.height = (containerHeight = '100%');
                    if(!parseInt(getComputedStyle(container).height)) container.style.height = (containerHeight = Math.floor(containerWidth * 1.5)) + 'px';
                }
            }
        }

        return new Promise((resolve) => {
            getUserTheme(container).then(() => {
                resolve();
            })
        })
    } 
}