<template>
  <div id="rs-locator">
    <template v-if="userIsAuthorized">
      <transition mode="out-in" name="slide-fade">
        <Locations v-if="map"/>
      </transition>
      <div class="rs-location-map-section">
        <LocationSearchComponent />
        <Map ref="map" v-on:initMap="setMap" v-if="initialized"/>
      </div>
    </template>
    <template v-else>
      <div class="rs-locator-unavailable">
        <div class="card">
          <h3>Locator Unavailable</h3>
          <p v-if="!userIsAuthorized && rsKeyAvailable">Your subscription has expired or does not include access to the RideStyler Locator. Please update your subscription via the <a :href="accountPortalURL">Account Portal</a> to restore access to this feature.</p>
          <p v-if="!userIsAuthorized && !rsKeyAvailable">The API key used to access the locator is invalid. Please see the <a :href="accountPortalURL + '/#/products/locator/general'">Account Portal</a> for instructions on how to set up the link to your locator.</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LocationSearchComponent from "./components/LocationSearchComponent.vue";
import Map from "./components/Map.vue";
import Locations from "./components/Locations";
import { mapMutations } from "vuex";
import Settings from "./Settings";
import StyleManager from "./StyleManager";
import L from "leaflet";

export default {
  name: "app",
  data(){
    return {
      map: null,
      initialized: false,
    };
  },
  async mounted(){
    await Promise.all([
        StyleManager.initialize.call(this),
        Settings.initialize()
    ]);
    this.initialized = true;
    let a = this;
    document.addEventListener("mapResize", function() {
      a.resize();
    });

  },
  methods: {
    setMap(newMap){
      if(newMap){
        this.setMapState(newMap);
        this.map = newMap;
      }
    },
    resize() {
      this.$refs.map.updateMapSize();
    },
    async updateLocations(locations){
      let locationGroup = null;

      this.isLoading = true;
      await this.setActiveLocations(locations);
      this.isLoading = false;

      if(this.activeLocations.length){
        locationGroup = new L.featureGroup(this.activeLocations.map(location => location.LocationMarker));
        this.map.flyToBounds(locationGroup.getBounds());
      }
    },
    ...mapMutations({
      setMapState: "setMap"
    })
  },
  computed: {
    userIsAuthorized() {
      return this.$store.state.isUserAuthorized;
    },
    rsKeyAvailable() {
      let apiKeyRegex = /Key=([a-zA-Z0-9\-]{32,36})/g,
      apiKeyMatch = apiKeyRegex.exec(window.location.pathname.substr(1)) || apiKeyRegex.exec(window.location.search.substr(1));

      return apiKeyMatch != null;
    },
    accountPortalURL(){
      let env = this.$store.state.ridestylerEnv,
          accountURL = undefined;

      if(env == "live") accountURL = `https://account.ridestyler.com`;
      else accountURL = `https://account-${env}.ridestyler.com`;

      return accountURL;
    } 
  },
  components: {
    Map,
    Locations,
    LocationSearchComponent
  }
};
</script>

<style lang="scss">
@import "./scss/main.scss";
</style>
<style>
@import "https://static.ridestyler.net/rs-ui/latest/rsui-icons.min.css";
@import "https://unpkg.com/leaflet@1.2.0/dist/leaflet.css";
</style>
