import store from './store/index';

function detectRideStylerEnvironment() {
    const url = window.ridestyler.ajax.url('', undefined);

    if (/\/api-alpha\./i.test(url)) store.state.ridestylerEnv = 'alpha';
    else if (/\/api-beta\./i.test(url)) store.state.ridestylerEnv = 'beta';
    else if (/\/api\./i.test(url)) store.state.ridestylerEnv = 'live';
    else store.state.ridestylerEnv = 'other';
}

function getSubscriptionProducts() {
    return new Promise(function (resolve, reject){
        window.ridestyler.ajax.send({
            action: "Auth/Status",
            callback: function (response){
                if (response.Success) {
                    if(response.SubscriptionPlan && response.SubscriptionPlan.Code != "RideStylerFreeBasePlan"){
                        store.state.isUserAuthorized = true;
                    } else {
                        store.state.isUserAuthorized = false;
                    }
                    resolve(response.Locations);
                } else {
                    reject();
                }
            }
        });
    });
}

const availableUnits = ['miles', 'kilometers'];

/**
 * @typedef {'live'|'beta'|'alpha'|'other'} RideStylerAPIEnvironment
 */

const settings = {
    initialize() {
        return new Promise((resolve) =>{
            detectRideStylerEnvironment();
            getSubscriptionProducts();
            for(let setting in this.options){
                if(store.state[setting] != null){
                    if (typeof store.state[setting] === 'object')
                        store.state[setting] = Object.assign({...store.state[setting], ...this.options[setting]});
                    else {
                        let customValue = this.options[setting];
                        if (setting == 'units' && availableUnits.indexOf(customValue) === -1) {
                            customValue = availableUnits[0];
                        }
                        store.state[setting] = customValue;
                    }
                }
            }
            resolve();
        });
    },
    
    /** @type {import('./main').RideStylerLocationOptions} */
    options: {},

    /** @type {RideStylerAPIEnvironment} */
    rideStylerEnvironment: undefined,
};

export default settings;