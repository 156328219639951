<template>
  <div class="location-search-bar">
    <div class="form-group">
      <i
        v-if="isUserLocatable"
        class="location-search-user icon icon-crosshairs"
        :class="{'text-gray' : !currentLocationIsActive}"
        v-on:click="locateUserClick"
        alt="Get Your Current Location"
      ></i>
      <input
        type="search"
        class="location-search-input"
        :placeholder="userLocation.address || 'Enter an address, city or zip'"
        v-model="newUserLocation"
        v-on:input="updateUserLocation()"
      />
      <img src="https://static.ridestyler.net/images/loaders/loader_radial_chaser_back_on_white_32.gif" class="input-loader" v-show="isLoading" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "LocationSearchComponent",
  data(){
    return {
      newUserLocation: undefined,
      isOrdered: false,
      searchTimeout: undefined,
      maxDistance: this.$store.state.maxDistance,
      filters: [],
      isLoading: false,
      isUserLocatable: false,
    };
  },
  mounted(){
    if(window.location.protocol){
      this.isUserLocatable = true;
    }
  },
  methods: {
    updateUserLocation(){
      let address = this.newUserLocation,
          self = this;

      if(this.searchTimeout) clearTimeout(this.searchTimeout);

      if(address){
        self.isLoading = true;
        self.searchTimeout = setTimeout(() => {
          self.createNewUserLocation(address).then(() => {
            self.isLoading = false;
            self.setCurrentLocationIsActive(false);
            self.newUserLocation = address;
          });
        }, 1700);
      }
    },
    getKeywordLocations(locations){
      let input = this.searchInput.toLowerCase();

      return locations.filter(function(location) {
        let locationAddress =
          location.LocationAddress1.toLowerCase() +
          ", " +
          location.LocationCity.toLowerCase() +
          ", " +
          location.LocationState.toLowerCase();

        return locationAddress.includes(input);
      });
    },
    async getLocationsWithNewDistance(){
      let newLocations = null;

      this.setMaxDistance(this.maxDistance);

      newLocations = await this.getNearbyLocations();

      return newLocations;
    },
    getOrderedLocations(locations){
      let orderedLocations = [];

      locations.forEach(function(location) {
        orderedLocations.push(location);
      });

      return orderedLocations.sort((a, b) =>
        a.LocationCity > b.LocationCity ? 1 : -1
      );
    },
    locateUserClick(){
      if(!this.currentLocationIsActive){
        this.clearMapMarkers().then(async () => {
          await this.getUserLocation();
          this.setCurrentLocationIsActive(true);
          this.newUserLocation = undefined;
        });
      }
    },
    ...mapActions({
      getLocationFromAddress: "getLocationFromAddress",
      getNearbyLocations: "getNearbyLocations",
      getUserLocation: "getUserLocation",
      clearMapMarkers: "clearMapMarkers",
      focusLocation: "focusLocation",
      createNewUserLocation: "createNewUserLocation",
    }),
    ...mapMutations({
      setCurrentLocationIsActive: "setCurrentLocationIsActive"
    })
  },
  computed: {
    ...mapGetters({
      locations: "locations",
      map: "map",
      userLocation: "userLocation",
      units: "units",
      currentLocationIsActive: "currentLocationIsActive"
    }),
  }
};
</script>

<style lang="scss">
#rs-locator {
  .location-search-bar {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    height: 55px; 
    .form-group {
      position: relative;
      .location-search-input {
        position: absolute;
        z-index: 1;
        width: 350px;
        padding: 10px 3px 10px 40px;
        top: 15px;
        right: 15px;
        -webkit-box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.5);
        border: 1px solid lightgray;
        height: 48px;
        background-color: white;
      }
      i.location-search-user {
        position: absolute;
        z-index: 2;
        right: 335px;
        cursor: pointer;
        top: 15px;
        height: 48px;
        line-height: 28px;
        padding: 10px 0px;
        cursor: pointer;
        -webkit-filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.1));
        filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.1));
        &.text-gray {
          color: lightgray;
          filter: none;
        }
      }
      .input-loader {
        position: absolute;
        z-index: 1;
        width: 28px;
        height: auto;
        top: 15px;
        line-height: 28px;
        padding: 10px 0px;
        right: 329px;
      }
    }
    @media screen and (max-width: 400px) {
      i.location-search-user {
        right: 220px;
      }
      .location-search-input {
        width: 235px;
      }
    }
    @media screen and (max-width: 840px){
      i.location-search-user {
        right: 236px;
      }
      .location-search-input {
        width: 250px;
      }
    }
  }
}
</style>
