import L from "leaflet";


const actions = {
    /**
     * Takes user input address
     * @param {Element} e 
     */
    createNewUserLocation(context, e){
        let address = undefined,
            newUserLocation = {},
            newLatLng = { lat: null, lng: null },
            userMarker = context.state.currentLocationMarker,
            map = context.getters.map;

        if(e) address = e;

        if (address){
            context.dispatch('getLocationFromAddress', address).then(function(location){
                
                let feature = undefined;
                if (location && location.features[0]){
                    //grab the first result
                    feature = location.features[0];
                }
                
                if( feature && map) {
                    if(userMarker) map.removeLayer(userMarker);
                    newLatLng.lat = feature.center[1];
                    newLatLng.lng = feature.center[0];
                    newUserLocation.latLng = newLatLng;
                    newUserLocation.address = address;

                    userMarker = L.circle(newUserLocation.latLng, { radius: 200 }).addTo(context.getters.map);
                    map.setView(newUserLocation.latLng, 10);

                    context.commit('setUserLocation', newUserLocation);
                    context.commit('setCurrentLocationMarker', userMarker);
                    window.localStorage['rs-locator-uselocation'] = JSON.stringify(newUserLocation);
                }
            });
        }
    },
    
    /**
     * Sets up location markers given an array of locations
     * @param {Array} locations 
     */
    setupLocationMarkers(context, locations){
        let icon = L.icon({
                iconUrl: "https://static.ridestyler.net/images/icons/map_marker_normal.svg",
                iconSize: [30, 87],
                iconAnchor: [15, 70]
            });

        locations.forEach(async function (location){
            let latLong = [location.LocationLatitude, location.LocationLongitude],
                locationSettings = {location: location, zoomLevel: 15, locationEl: null};

            if(latLong){
                location.LocationMarker = L.marker(latLong, {icon: icon}).addTo(context.getters.map);
                location.LocationMarker._icon.id = 'location-marker-' + location.LocationID;
                location.LocationMarker.addEventListener('click', () => {
                    context.dispatch('focusLocation', locationSettings);
                });
            }
        });
    },

    /**
     * Adds selected state to marker given the marker's location obj
     * @param {Object} location
     */
    selectMarker(context, location){
        let selectedIconURL = "https://static.ridestyler.net/images/icons/map_marker_selected.svg",
            markerIconURL = "https://static.ridestyler.net/images/icons/map_marker_normal.svg",
            currentSelectedMarker = document.querySelector('.selected-marker');

        if(currentSelectedMarker){
            currentSelectedMarker.src = markerIconURL;
            currentSelectedMarker.classList.remove('selected-marker');
        }

        if (typeof location !== 'undefined') {
            location.LocationMarker._icon.src = selectedIconURL;
            location.LocationMarker._icon.classList.add('selected-marker');
        }
    },

     /**
     * Triggers location selection
     * @param {Object} settings 
     */
    selectLocation(context, settings) {

        if (settings.location){
            //if the location isn't set, set it
            if(context.state.currentSelectedLocation != settings.location){
                context.dispatch("focusLocation", settings);
            }
        }

        let event = new CustomEvent("rs-location-selected", {detail: {location:settings.location}} );
        document.dispatchEvent(event);
    },

    /**
     * Zooms in on a given location
     * @param {Object} settings 
     */
    focusLocation(context, settings){
        let latLong = { lat: settings.location.LocationLatitude, lng: settings.location.LocationLongitude },
            map = context.getters.map;

        if (settings.location){
            if (!settings.zoomLevel) map.setView(latLong, 10);
            else map.setView(latLong, settings.zoomLevel);
            if(context.state.currentSelectedLocation == settings.location){
                context.commit('setCurrentSelectedLocation', undefined);
                context.dispatch('selectMarker', undefined);
            } else {
                context.dispatch('selectMarker', settings.location);
                context.commit('setCurrentSelectedLocation', settings.location);
            }
        }
        if (settings.locationEl !== undefined){
            if(settings.locationEl == null) settings.locationEl = document.querySelector("li[name='location-" + settings.location.LocationID + "']");

            settings.locationEl.scrollIntoView({behavior: "smooth"});
        }
    },

    /**
     * Get directions to a location returns URL string to google maps
     * @param {Object} toLocation 
     * @returns {String}
     */
    async getDirections(context, toLocation){
        let googleDirections = "https://www.google.com/maps/dir/";

        if (context.getters.userLocation.address) googleDirections += context.getters.userLocation.address + '/';
        if (toLocation) googleDirections += await context.dispatch('getLocationAddress', toLocation);

        return googleDirections;
    },

    /**
     * Get the address string given a location object
     * @param {Object} location 
     * @returns {String}
     */
    getLocationAddress(context, location){
        let locationAddress = null;

        locationAddress =
            location.LocationAddress1 +
            ", " +
            location.LocationCity +
            ", " +
            location.LocationState;

        return locationAddress;
    },

    /**
     * Get the addres given an object with lat and lng
     * @param {Object} latLng 
     * @returns {Promise}
     */
    getLatLngAddress(context, latLng){
        let mbx = require('@mapbox/mapbox-sdk'),
            mbxClient = new mbx({ accessToken: process.env.VUE_APP_MAP_BOX });

        return new Promise(function (resolve){
            mbxClient.createRequest({
                method: 'GET',
                path: '/geocoding/v5/mapbox.places/' + latLng.lng + ',' + latLng.lat + '.json'
            }, {})
                .send()
                .then(response => {
                    // GeoJSON document with geocoding matches
                    if (response.body.features) resolve(response.body.features[0].place_name);
                    else resolve(null);
                });
        });
    },

    /**
     * Get a location object given an address
     * @param {String} address 
     * @returns {Promise}
     */
    getLocationFromAddress(context, address){
        let mbx = require('@mapbox/mapbox-sdk'),
            mbxClient = new mbx({ accessToken: process.env.VUE_APP_MAP_BOX });

        return new Promise(function (resolve){
            mbxClient.createRequest({
                method: 'GET',
                path: '/geocoding/v5/mapbox.places/' + address + '.json?country=US,CA'
            }, {})
                .send()
                .then(response => {
                    // GeoJSON document with geocoding matches
                    resolve(response.body);
                });
        });
    },

    /**
     * Get all locations
     */
    getLocations(context){
        return new Promise(function (resolve, reject){
            let options = context.getters.locationFilter;
            if(context.state.mode === "Best") {
                options.Best = {
                    Location: {
                        Latitude: context.getters.userLocation.latLng.lat,
                        Longitude: context.getters.userLocation.latLng.lng
                    },
                    Limit: context.getters.maxDistance
                }
            } else if(context.state.mode === "WithinRadius"){
                options.WithinRadius = {
                    Center: {
                        Latitude: context.getters.userLocation.latLng.lat,
                        Longitude: context.getters.userLocation.latLng.lng
                    },
                    Radius: context.getters.maxDistance
                };
            } else if(context.state.mode === "Nearest"){
                options.Nearest = {
                    Location: {
                        Latitude: context.getters.userLocation.latLng.lat,
                        Longitude: context.getters.userLocation.latLng.lng
                    },
                    Limit: context.getters.maxDistance
                }
            } 

            window.ridestyler.ajax.send({
                action: "Location/List",
                data: options,
                callback: function (response){
                    if (response.Success)
                    {
                        let event = new CustomEvent("rs-location-searched", {detail: {locations:response.Locations}} );
                        document.dispatchEvent(event);
                        resolve(response.Locations);
                    }    
                    else reject();
                }
            });
        });
    },

    /**
     * Get all locations nearby user's current location
     */
    async getNearbyLocations(context){
        context.commit('setActiveLocationsLoading', true);

        let locations = null;

        locations = await context.dispatch('getLocations');

        return new Promise(async function (resolve){
            context.commit('setLocations', locations);
            context.commit('setActiveLocations', locations);
            context.commit('setActiveLocationsLoading', false);
            resolve(locations);

        });
    },

    /**
     * Get the user's location
     */
    getUserLocation(context){
        let map = context.getters.map,
            newUserLocation = { latLng: null },
            userMarker = context.state.currentLocationMarker;

        return new Promise(function (resolve){
            map.locate();
            map.on("locationfound", async function (e){
                if(userMarker) map.removeLayer(userMarker);
                newUserLocation.latLng = e.latlng;
                userMarker = L.circle(newUserLocation.latLng, { radius: 200 }).addTo(map);
                newUserLocation.address = await context.dispatch('getLatLngAddress', newUserLocation.latLng);

                map.setView(newUserLocation.latLng, 10);

                context.commit('setCurrentLocationMarker', undefined);
                context.commit('setUserLocation', newUserLocation);
                context.commit('setCurrentLocationMarker', userMarker);
                window.localStorage['rs-locator-uselocation'] = JSON.stringify(newUserLocation);
                
                resolve(newUserLocation);
            });
        });
    },

    /**
     * Clear all map markers
     */
    clearMapMarkers(context){
        
        let map = context.getters.map;

        map.eachLayer(function (layer){
            if (layer._icon && layer._icon.classList.contains('leaflet-marker-icon') !== 0){ ///checking it's a marker and not the map
                map.removeLayer(layer);
            }
        });
    },

    /**
     * Get the distance away from a location the the user's current location
     * @param {Object} location 
     */
    async getDistanceAwayFromUser(context, location){
        let distanceInMeters = null,
            latLng = [location.LocationLatitude, location.LocationLongitude],
            map = context.getters.map,
            distance = null;

        distanceInMeters = await map.distance(context.getters.userLocation.latLng, latLng);

        if (context.getters.units == 'miles') distance = await context.dispatch('convertMetersToMiles', distanceInMeters);
        else distance = (distanceInMeters / 1000).toFixed();

        return parseInt(distance)
    },

    /**
     * Convert meters to miles
     * @param {String} meters 
     * @returns {String}
     */
    async convertMetersToMiles(context, meters){
        let metersToMiles = (meters * 0.000621).toFixed();

        return metersToMiles;
    },

    /**
     * Get Latitude and longitude array given a location object
     * @param {*} location 
     * @returns {Array} 
     */
    async getLatLong(context, location){
        let latLng = [];

        if (location) latLng = [location.LocationLatitude, location.LocationLongitude];

        return latLng;
    },

    /**
     * Get abbreviation for units
     */
    unitsAbr(context){
        if (context.getters.units == "miles") return "mi.";
        else return "km.";
    },
}

export default actions;