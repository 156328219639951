/**
 * Parse time matches in string to normalized TimeObjs. Parts of the time that weren't matched will be null in TimeObjs.
 * @param {String} timeString 
 * @returns {TimeObj[]} Array of all time objects parsed from string
 */
 export function parseTimeObjectsFromString (timeString) {
    if (!timeString) return [];

    //Pull out reasonable time matches from the string
    let regex = /([0-2]?[0-9])[^0-9\-\n\ra-z]*([0-9]{0,2})\s*(am|pm)?/gmi;
    let matches = timeString.matchAll(regex);

    let timeObjs = [];
    for (let match of matches) {
        timeObjs.push(createTimeObjectFromRawParts(match[1], match[2], match[3]));
    }

    return timeObjs;
}

/**
 * Convert separate parts of a time of day into a TimeObj
 * @param {String} hours String representation of an interger of hour of the day
 * @param {String} minutes String representation of an interger of minutes
 * @param {String} amPm 'am' or 'pm'
 * @returns {TimeObj} Will return with a valid integer for hours and minutes and either 'am', 'pm', or null for amPm. If raw parts cannot be parsed then return null for every property.
 */
 export function createTimeObjectFromRawParts(hours, minutes, amPm) {
    // Normalize AM/PM case if it is a string for comparison
    if (typeof(amPm) === 'string') amPm = amPm.toLowerCase();

    if (hours) {
        let hoursInt = parseInt(hours);
        if (!isNaN(hoursInt)) {
            let minutesInt = parseInt(minutes);
            if (isNaN(minutesInt)) minutesInt = 0; //default minutes to 0 if it didn't parse

            let validAmPm = (amPm === "am" || amPm === "pm") ? amPm : null;
            if (validAmPm === null) {
                if (hoursInt > 12) {
                    validAmPm = 'pm';
                    hoursInt -= 12;
                } else if (hoursInt === 0) {
                    validAmPm = 'am';
                    hoursInt = 12;
                }
            }

            return { hours: hoursInt, minutes: minutesInt, amPm: validAmPm };
        }
    }

    return { hours: null, minutes: null, amPm: null };
}

/**
 * Parses a string representing a time of the day
 * @param {String} timeString 
 * @returns {TimeObj|null} TimeObj representing first parsed time from string. Null if no time could be parsed.
 */
export function parseTimeObjectSingle (timeString) {
    let parsedTimeObjs = parseTimeObjectsFromString(timeString);
    if (!parsedTimeObjs.length) return null;
    
    //set amPm if not set yet
    let timeObj = parsedTimeObjs[0];
    if (!timeObj.amPm) timeObj.amPm = "am"; //default assume 24 hour time making it 'am'

    return timeObj;
}

/**
 * Parses a string representing a time range (open-close) into an object holding open and close TimeObjs
 * @param {String} timeRangeString 
 * @returns {Object|null} Returns {open: TimeObj, close: TimeObj} holding the open and close time objects represented by passed in string. Returns null if 2 time objects were not parsed.
 */
export function parseTimeObjectsRange (timeRangeString) {
    let parsedTimeRange = parseTimeObjectsFromString(timeRangeString);
    if (parsedTimeRange.length !== 2) return null;

    let openTime = parsedTimeRange[0];
    let closeTime = parsedTimeRange[1];

    //figure out open am/pm if it wasn't parsed
    if (!openTime.amPm) {
        if (closeTime.amPm === "am") openTime.amPm = "am"; //doesn't make sense to open in pm and close in am
        else if (openTime.hours > closeTime.hours && openTime.hours !== 12) {  //doesn't make sense to close before you open that day. Since close is pm, assume that 12 means pm as well and not am.
            openTime.amPm = "am";
            if (!closeTime.amPm) closeTime.amPm = "pm";
        } 
        else if (openTime.hours === closeTime.hours) { //fair to assume they don't open and close right away
            openTime.amPm = "am";
            if (!closeTime.amPm) closeTime.amPm = "pm";
        }
        else if (openTime.hours < 5 || openTime.hours === 12) openTime.amPm = "pm"; //fair to assume (after other checks) that open is not in the middle of the night
        else openTime.amPm = "am"; //assume 24 hour time them
    }

    //figure out close am/pm if it wasn't parsed
    if (!closeTime.amPm) {
        if (openTime.amPm === "pm") closeTime.amPm = "pm"; //doesn't make sense to open in pm and close in am
        else if (openTime.hours > closeTime.hours) { //assume open is am here otherwise it will close before its open, so close has to be pm
            closeTime.amPm = "pm";
        }
        else if (openTime.hours === closeTime.hours) { //fair to assume they don't open and close right away or close is not before open
            closeTime.amPm = "pm";
        }
        else if (closeTime.hours < 7 || closeTime.hours === 12) closeTime.amPm = "pm"; //fair to assume (after other checks) that close is not during early morning
        else closeTime.amPm = "am"; //assume 24 hour time them
    }

    return {open: openTime, close: closeTime};
}

/**
 * Convert TimeObj hours, minutes, and amPm into a display string of the represented time
 * @param {TimeObj} timeObj 
 * @returns {String} Display string of the represented time in the format hours:minutes[am|pm]
 */
export function getTimeObjectDisplayString (timeObj) {
    if (timeObj && timeObj.hours !== undefined && timeObj.hours !== null && timeObj.minutes !== undefined && timeObj.minutes !== null && timeObj.amPm)
        return timeObj.hours.toString() + ":" + (timeObj.minutes < 10 ? "0" : "") + timeObj.minutes.toString() + " " + timeObj.amPm;
    else
        return null;
}

/**
 * @typedef {Object} TimeObj 12 hour representation of a time
 * @property {number} hours hour part of time in 12 hour notation
 * @property {number} minutes minute part of time
 * @property {String} amPm 'am' or 'pm'
 */