import state from './state';

const mutations = {
    ... (function () {
        const setters = {};

        for (let k in state) {
            const setterName = 'set' + k[0].toUpperCase() + k.slice(1);

            setters[setterName] = (state, val) => { state[k] = val };
        }

        return setters;
    })(),
    setMap(state, newMap) {
        state.map = newMap;
    },
    setActiveLocations(state, newLocations) {
        state.activeLocations = newLocations;
    },
    setActiveLocationsLoading(state, loading) {
        state.activeLocationsLoading = loading;
    },
    setUserLocation(state, newLocation) {
        state.activeLocationsLoading = true;
        state.userLocation = newLocation;
    },
    setLocations(state, newLocations) {
        state.locations = newLocations;
    },
    setMaxDistance(state, newDistance){
        state.maxDistance = newDistance;
    },
    setCurrentLocationIsActive(state, newValue){
        state.currentLocationIsActive = newValue;
    },
    setMapboxID(state, newID){
        state.mapboxID = newID;
    }
}

export default mutations;