<template>
  <div class="side-bar-wrapper">
    
    <location-search-component />
    <ul class="location-list" v-bind:class="{ loader: isLoading }" v-if="activeLocations.length > 0">
      <LocationComponent
        v-for="locationObject in activeLocations"
        v-bind:key="locationObject.LocationID"
        v-bind:location="locationObject"
        v-on:clickEvent="focusLocation"
        v-on:selectLocation="selectLocation"
      />
    </ul>
    <div class="no-results" v-else-if="activeLocations.length == 0 && userLocation.address && !activeLocationsLoading">
       <h4>{{ locationOutOfDistanceMessage  }}</h4>
       <template v-if="locationOutOfDistanceButtonLabel != ''">
        <button class="locations-out-of-distance-button" @click="locationOutOfDistanceButtonClick">{{ locationOutOfDistanceButtonLabel }}</button>
        <div class="form-divider-text">or</div>
        <div class="form-divider"></div>
       </template>
        <form class="form-group" @submit.prevent="createNewUserLocation(userLocationInput)">
          <input v-model="userLocationInput" placeholder="City, State or Zip Code" />
          <button class="search-button" type="submit">Search</button>
        </form>
    </div>
    <div class="side-bar-landing" v-else>
      <img :src="theme.CompanyLogoLight" v-if="theme && theme.CompanyLogoLight" class="locator-company-logo"/>
      <i class="icon icon-search-location" v-else></i>
      <h4>Find a location near you</h4>
      <button class="use-my-location" @click="locateUserClick">Use my location</button>
      <div class="form-divider-text">or</div>
      <div class="form-divider"></div>
      <form class="form-group" @submit.prevent="createNewUserLocation(userLocationInput)">
        <input v-model="userLocationInput" placeholder="City, State or Zip Code" />
        <button class="search-button" type="submit">Search</button>
      </form>
    </div>
  </div>
</template>

<script>
import LocationComponent from "../components/LocationComponent";
import LocationSearchComponent from '../components/LocationSearchComponent';
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Locations",
  data: function() {
    return {
      isLoading: false,
      userLocationInput: undefined,
    };
  },
  mounted() {
    this.$nextTick(()=>{
      var l = this;
      setTimeout(function(){
        l.loadSaved();
        
      }, 250);
      
    });
  },
  methods: {
    loadSaved() {
        if(window.localStorage['rs-locator-uselocation']) {
          this.createNewUserLocation(this.$store.state.userLocation.address);
        }
        else if( this.$store.state.defaultSearch != "" )
        {
          this.createNewUserLocation(this.$store.state.defaultSearch);
        }
    },
    locationOutOfDistanceButtonClick(){
      let event = new CustomEvent("rs-location-out-of-distance", {
        detail: {
          userLocation: this.$store.state.userLocation
        }
      });
      document.dispatchEvent(event);
    },
    locateUserClick(){
      this.locateUser();
      this.setCurrentLocationIsActive(true);
    },
    ...mapActions({
      locateUser: "getUserLocation",
      focusLocation: "focusLocation",
      selectLocation: "selectLocation",
      getNearbyLocations: "getNearbyLocations",
      setupLocationMarkers: "setupLocationMarkers",
      clearMapMarkers: "clearMapMarkers",
      createNewUserLocation: "createNewUserLocation",

    }),
    ...mapMutations({
      setCurrentLocationIsActive: "setCurrentLocationIsActive"
    })
  },
  computed: {
    locationOutOfDistanceButtonLabel(){
      if(this.$store.state.locationOutOfDistanceButtonLabel == null) {
        return '';
      } else {
        return this.$store.state.locationOutOfDistanceButtonLabel;
      }
    },
    locationOutOfDistanceMessage(){
      return this.$store.state.locationOutOfDistanceMessage.replace(/\{maxDistance\}/g, this.$store.state.maxDistance);
    },
    ...mapGetters({
      activeLocations: "activeLocations",
      activeLocationsLoading: "activeLocationsLoading",
      locations: "locations",
      userLocation: "userLocation",
      theme: "theme"
     
    })
  },
  watch: {
    userLocation() {
      this.getNearbyLocations();
    },
    locations(newLocations) {
      this.clearMapMarkers();
      this.setupLocationMarkers(newLocations);
    }
  },
  components: {
    LocationComponent,
    LocationSearchComponent
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#rs-locator {
  .locator-company-logo {
    width: 50%;
    height: auto;
    display: inline-block;
    margin: 20px auto;
  }
  .side-bar-wrapper {
    border: 1px solid lightgray;
    width: 25%;
    min-width: 400px;
    height: 100%;
    position: relative;
    .location-search-bar {
      width: 100%;
      position: relative;
    }
    .side-bar-landing {
      display: -webkit-box;
      display: -ms-flexbox;
      display: inline-flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      width: 90%;
      text-align: center;
      position: absolute;
      margin: 0 auto;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      padding: 5%;
      .icon-search-location {
        font-size: 2.3rem;
        margin-bottom: 10px;
      }
      h3 {
        margin: 20px 0;
      }
      .form-divider-text {
        position: relative;
        top: 9px;
        display: inline-block;
        background: white;
        width: 40px;
        margin: 0 auto;
      }
      .form-divider {
        border-bottom: 1px solid lightgray;
        margin: -4px 0 32px;
      }
      .use-my-location {
        width: 100%;
        display: inline-block;
        margin: 10px auto 20px;
        padding: 10px;
        color: white;
      }
      .form-group {
        display: flex;
        flex-direction: row;

        input {
          width: 70%;
          height: 48px;
          margin-right: 2%;
          padding-left: 10px;
        }
        button {
          padding: 0 10px;
          width: 30%;
          color: white;
        }
      }
    }
    .location-list {
      position: relative;
      overflow: auto;
      height: 100%;
      width: 100%;
      margin: 0;

      li {
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .no-results {
      display: -webkit-box;
      display: -ms-flexbox;
      display: inline-flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      width: 90%;
      text-align: center;
      position: absolute;
      margin: 0 auto;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      padding: 5%;

      .form-divider-text {
        position: relative;
        top: 9px;
        display: inline-block;
        background: white;
        width: 40px;
        margin: 0 auto;
      }
      .form-divider {
        border-bottom: 1px solid lightgray;
        margin: -4px 0 32px;
      }
      .locations-out-of-distance-button {
        width: 100%;
        display: inline-block;
        margin: 10px auto 20px;
        padding: 10px;
        color: white;
      }

      span {
        position: relative;
        top: 20px;
      }
      .form-group {
        display: flex;
        flex-direction: row;

        input {
          width: 70%;
          height: 48px;
          margin-right: 2%;
          padding-left: 10px;
        }
        button {
          padding: 0 10px;
          width: 30%;
          color: white;
        }
      }
    }
    @media screen and (max-width: 840px){
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      overflow: auto;
      background: white;
      z-index: 11;
      border: none;
      .location-list {
        position: absolute;
        overflow: auto;
        width: 100%;
        height: auto;
        top: 55px;
        bottom: 0;
        margin: 0;
      }
      .no-results {
        top: 55px;
        bottom: 0;
        width: 90%;
      }
      .side-bar-landing {
        top: 55px;
        width: 100%;
      }
      .location-search-bar {
        .form-group {
          height: 100%;
          .input-loader {
            position: absolute;
            width: auto;
            height: 100%;
            top: 0;
            padding: 10px 7px;
            left: 0%;
          }
          i.location-search-user {
            position: absolute;
            z-index: 2;
            left: 0%;
            top: 0px;
            height: 100%;
            width: 50px;
            padding: 10px 7px;
            text-align: center;
            &::before {
              display: inline;
              vertical-align: middle;
            }
          }
          .location-search-input {
            position: absolute;
            z-index: 1;
            width: 100%;
            padding: 10px 13px 10px 50px;
            border: 1px solid lightgray;
            height: 100%;
            right: 0;
            top: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
          }
        }
      }
    }
    @media screen and (min-width: 841px) {
      .location-search-bar {
        display: none;
      }
    }
  }
}
</style>
