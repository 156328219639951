<template>
  <li class="rs-location" :class="{ 'active' : isActiveLocation }" v-bind:name="'location-' + location.LocationID" v-on:click="onClick($event)">
    <div class="rs-location-image" v-if="location.LocationDefaultImageUrl && isActiveLocation">
      <img :src="location.LocationDefaultImageUrl" />
    </div>
    <a v-if="location.LocationDetailsUrl" class="more-details" :href="location.LocationDetailsUrl" target="_blank">More Details</a>
    <a class="rs-location-name">{{ location.LocationName }}</a>
    <p class="rs-location-address">
      <i class="icon icon-map-marker-alt"></i>
      <span class="line-1">{{ location.LocationAddress1 }}</span>
      <span class="line-2">{{ location.LocationCity }}, {{ location.LocationState }} {{ location.LocationPostalCode }}</span>
    </p>
    <p class="rs-location-sales-phone" v-if="location.LocationSalesPhone">
      <i class="icon icon-phone-alt"></i>
      <a v-bind:href="'tel:' + location.LocationSalesPhone">{{ formatPhone(location.LocationSalesPhone) }}</a>
    </p>
    <p class="rs-location-hours" v-if="location.LocationHours">
      <i class="icon icon-clock"></i><a class="rs-toggle-hours" @click="hoursVisible = !hoursVisible">{{ !hoursVisible ? "Operating hours" : "Hide"}}</a>
      <LocationHoursTable :hours="location.LocationHours" v-show="hoursVisible" />
    </p>
    <p class="rs-location-directions">
      <a :href="directions" target="_blank">Get Directions</a>
      <span>
        <i class="icon icon-route"></i>
        {{ getDistanceWithUnits(location) }}
      </span>
    </p>
    
    <p class='rs-location-selector' v-if="showLocationSelector">
      <button v-on:click="setLocation($event)">Set as Location</button>
    </p>
  </li>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LocationHoursTable from "./LocationHoursTable";

export default {
  name: "LocationComponent",
  data() {
    return {
      locationLatLong: [],
      directions: "",
      hoursVisible: false
    };
  },
  mounted() {
    let self = this;
    this.getDirections(this.location).then(function(googleDirections) {
      if (googleDirections) self.directions = googleDirections;
    });

    if( this.$store.state.loadLocation != undefined && this.$store.state.loadLocation == this.location.LocationID ) {
      this.$emit("clickEvent", {location: this.location, zoomLevel: 15, locationEl: this.$el});
      this.$store.state.loadLocation = undefined;
    }
    
  },
  methods: {
    formatPhone(phone){
      var cleaned = ('' + phone).replace(/\D/g, '');
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
          var intlCode = (match[1] ? '+1 ' : '');
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
      else return phone;
    },
    onClick(e) {
      let locationEl = null;

      if(e.target.localName != 'a'){
        if (e.target.classList.contains("location")) locationEl = e.target;
        else locationEl = e.target.parentElement;
      }

      if(locationEl){
        this.$emit("clickEvent", {location: this.location, zoomLevel: 15, locationEl: locationEl});
      }
    },
    setLocation(e) {
      e.preventDefault();
      e.stopPropagation();

      let locationEl = e.target.parentElement.parentElement;

      if(locationEl){
        this.$emit("selectLocation", {location: this.location, zoomLevel: 15, locationEl: locationEl});
      }
      
    },
    ...mapActions({
      getDirections: "getDirections"
    }),
    getDistanceWithUnits(location) {
      // LocationDistance in miles from RS
      let divisor = this.units == "miles" ? 1 : 0.621;
      return (Math.round((location.LocationDistance / divisor + Number.EPSILON) * 100) / 100).toFixed(2) +  " " + this.unitsDisplayed + " away";
    }
    
  },
  computed: {
    ...mapGetters({
      units: "units",
      useUnitsAbbr: "useUnitsAbbr",
      showLocationSelector:"showLocationSelector"
    }),
    /**
     * Get abbreviation for units
     */
    unitsAbbr() {
      if (this.units == "miles") {
        return "mi.";
      } else {
        return "km.";
      }
    },
    
    unitsDisplayed() {
      return this.useUnitsAbbr ? this.unitsAbbr : this.units;
    },

    isActiveLocation(){
      return this.location == this.$store.state.currentSelectedLocation;
    }
  },
  components: {
    LocationHoursTable
  },
  props: {
    location: Object
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.rs-location {
  display: block;
  text-align: left;
  border-bottom: 1px solid lightgrey;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 auto;
  .rs-location-image {
    width: 100%;
    margin-bottom: 10px;
    img {
      width: auto;
      max-width: 100%;
      max-height: 50px
    }
  }

  p {
    color: gray;
    position: relative;
    padding-left: 20px !important;
    font-size: 0.8rem;
    line-height: 1.1rem;
    margin: 0.8rem 0;
  }
  p.rs-location-directions {
    margin-bottom: 0;
    padding-left: 0;

    span {
      float: right;
    }
  }
  p.rs-location-selector {
    padding-left:0;
    
    button {
      color:white;
      padding:15px;
      margin-top: 1rem;
    }
  }
  a.more-details {
    margin-left: 6px;
    margin-right: 4px;
    font-weight: bold;
    float: right;
  }
  .icon {
    vertical-align: middle;
    position: absolute;
    right: 98%;
    top: 2px;

    &.icon-route {
      right: 3px;
      top: -2px;
      position: relative;
    }
  }
}
</style>
