<template>
    <div class="rs-location-hours">
        <div class="rs-location-hour-wrapper" v-for="(day, label) in hours" :key="label">
            <div class="rs-location-day-wrapper">
                <div class="rs-location-day">{{ label }}</div>
                <div class="rs-location-hour-range" v-if="day && day.Operational && (day.Open || day.Close)">
                    <div>
                        {{ getFormattedTime(day.Open) }}
                    </div>
                    <span> - </span>
                    <div>
                        {{ getFormattedTime(day.Close) }}
                    </div>
                </div>
                <div class="rs-location-hour-range" v-else-if="day && day.Operational">Open</div> 
                <div class="rs-location-hour-range" v-else>Closed</div>     
            </div>   
        </div>
    </div>
</template>

<script>

import * as utils from "@/helpers/utils";

export default {
    name: "LocationHours",
    data() {
        return {
        };
    },
    methods: {
        getFormattedTime(time){
            let timeString = "N/A";

            if (time)
            {
                //handle conversion from 12 or 24 hour notation as well as null out any bad data
                let timeObj = utils.parseTimeObjectSingle(time);
                //turn time object into displayable time string
                if (timeObj) timeString = utils.getTimeObjectDisplayString(timeObj);
            }

            return timeString;
        }
    },
    props: {
        hours: Object
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.rs-location-hours {
  .rs-location-hour-wrapper {
    width: 100%;
  }
  .rs-location-day-wrapper {
      display: grid;
      grid-template-columns: 120px auto;
  }
  .rs-location-day {
    font-weight: bold;
  }
  .rs-location-hour-range {
      display: flex;
      justify-content: flex-start;

      span {
        padding: 0 5px;
      }
  }
}
</style>
