import Vue from 'vue';
import Store from './store/index';
import App from './App';
import Settings from './Settings';

Vue.config.productionTip = false;

/**
 * @param {HTMLElement|HTMLElement[]|string} container
 * @param {RideStylerLocatorOptions} options
 */
function RideStylerLocator(container, options) {
  options = options || {};

  // Do some type checking on the container to accept, a string (CSS selector), HTML element, or array of HTML elements (such as a jQuery object)
  if (container && typeof container === 'object' && 'length' in container) {
    if (container.length > 0) container = container[0];
    else container = undefined;
  }

  // Check if we have the usersLocation stored in the local storage container
  if(window.localStorage['rs-locator-uselocation']) Store.state.userLocation = JSON.parse(window.localStorage['rs-locator-uselocation']);

  // Expose $locationSettings as a property on every Vue instance
  Vue.prototype.$locationSettings = Settings;

  Settings.options = options;

  // Make sure we have a container to instantiate into
  if (!container) throw "Unable to instantiate RideStylerLocator without a container";

  this.app = new Vue({
    el: container,
    store: Store,
    render: h => h(App)
  });
}

/**
 * @param {HTMLElement|HTMLElement[]|string} container
 * @param {RideStylerLocator} options
 */
RideStylerLocator.create = function (container, options) {
  return new RideStylerLocator(container, options);
};

window.RideStylerLocator = RideStylerLocator;

