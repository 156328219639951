const state = {
    map: null,
    activeLocations: [],
    activeLocationsLoading: true,
    locations: [],
    userLocation: {
        latLng: null,
        marker: null,
        address: null,
    },
    nearbyDistance: 5,
    prefferedDistance: 50,
    maxDistance: 500,
    mode: "Best",
    locationOutOfDistanceMessage: "We couldn't find any locations in your area. Would you like to try another search?",
    locationOutOfDistanceButtonLabel: '',
    mapScrollZoom: true,
    showLocationSelector: false,
    loadLocation:'',
    defaultSearch:'',
    units: 'miles',
    useUnitsAbbr: false,
    isUserAuthorized: true,
    currentLocationIsActive: false,
    currentLocationMarker: undefined,
    currentSelectedLocation: undefined,
    theme: undefined,
    mapboxID: "streets-v11",
    ridestylerEnv: undefined,
    locationFilter: {
        Count: 20
    },
    minZoomLevel: 8
}

export default state;
