<template>
  <div class="map-wrapper">
    <div id="mapid"></div>
    <a href="http://mapbox.com/about/maps" class='mapbox-wordmark' target="_blank"></a>
    <div class="attributions"><div class="attribution-box">© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong></div></div>
  </div>
</template>

<script>
import L from "leaflet";
import { mapGetters } from 'vuex';

export default {
  name: "Map",
  data() {
    return {
      map: null,
      tileLayer: null,
    };
  },
  async mounted() {
    await this.initializeMap();
    this.$emit("initMap", this.map);
  },
  methods: {
    initializeMap() {
      this.map = L.map("mapid", { scrollWheelZoom: this.mapScrollZoom, attributionControl: false }).setView([39.8283, -98.5795], 4);
      this.tileLayer = L.tileLayer(window.location.protocol + "//api.mapbox.com/styles/v1/mapbox/{id}/tiles/{z}/{x}/{y}?access_token=" + process.env.VUE_APP_MAP_BOX,
        {
          maxZoom: 18,
          minZoom: this.minZoomLevel,
          id: this.mapboxID,
        }
      );
      this.tileLayer.addTo(this.map);
    },
    updateMapSize() {
      this.map.invalidateSize();
    }
  },
  computed: {
    ...mapGetters({
      mapScrollZoom: 'mapScrollZoom',
      mapboxID: 'mapboxID',
      minZoomLevel: 'minZoomLevel'
    })
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#rs-locator {
  .map-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    #mapid {
      height: 100%;
      width: 100%;
      //display: inline-block;
      margin: 0 0 0 auto;
      z-index: 0;
      position: absolute;
    }
    .pop-rs-location-name {
      font-weight: bold;
      margin-bottom: 5px;
      border-bottom: 2px solid #ec432c;
      padding-bottom: 5px;
      text-transform: uppercase;
    }
    .leaflet-popup-content p:nth-child(1n + 2) {
      color: gray;
    }
    .leaflet-container a.leaflet-popup-close-button {
      padding: 10px 10px 0 0;
    }
    a {
      color: #ec432c;
    }
    .leaflet-control-zoom a,
    .leaflet-control-attribution a,
    .leaflet-popup-close-button {
      color: black;
    }
  }
}
</style>
