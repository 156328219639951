const getters = {
    map(state) {
        return state.map;
    },
    activeLocations(state) {
        return state.activeLocations;
    },
    activeLocationsLoading(state) {
        return state.activeLocationsLoading;
    },
    locations(state) {
        return state.locations;
    },
    userLocation(state) {
        return state.userLocation;
    },
    prefferedDistance(state) {
        return state.prefferedDistance;
    },
    nearbyDistance(state) {
        return state.nearbyDistance;
    },
    maxDistance(state) {
        return state.maxDistance;
    },
    units(state) {
        return state.units;
    },
    mapScrollZoom(state) {
        return state.mapScrollZoom;
    },
    showLocationSelector(state) {
        return state.showLocationSelector;
    },
    loadLocation(state) {
        return state.loadLocation;
    },
    defaultSearch(state) {
        return state.defaultSearch;
    },
    currentLocationIsActive(state){
        return state.currentLocationIsActive;
    },
    theme(state){
        return state.theme;
    },
    mapboxID(state){
        return state.mapboxID;
    },
    locationFilter(state) {
        return state.locationFilter;
    },
    minZoomLevel(state) {
        return state.minZoomLevel;
    },
    useUnitsAbbr(state) {
        return state.useUnitsAbbr;
    }
}

export default getters;